import { graphql } from 'react-apollo'
import Models           from '/models'


#
# Queries
#
import changes from './queries/index.gql'

export withChanges = graphql changes,
  options: (props)->
    { page, search, filters } = props.location.state or {}
    variables:
      target_type: props.match.params.klass
      target_id: props.match.params.id
      page: page or 1
      search: search or undefined
      filters: filters or {}
  props: ({props, data})->
    { nodes, pagination } = data.changes or {}
    changes: Models.Change.parse nodes or []
    changesPagination: pagination
    changesLoading: data.loading
