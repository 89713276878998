import Model, { obj_with_default } from '/lib/model'

import humanize   from 'underscore.string/humanize'
import currencies, { base_currencies } from '/lib/currencies'
import { formatMoney }  from 'accounting'

export default \
class ServiceInvoice extends Model
  @className: 'ServiceInvoice'

  constructor: ->
    super arguments...
    @id                = null # 123
    @currency          = null # CAD 
    @state             = null # open
    @amount_due        = null # 11.59
    @created_at        = null # 2018-10-30
    @invoice_pdf_url   = null # https://stripe.com/{invoice_id}/pdf
    @invoice_url       = null # https://pay.stripe.com/invoice/{invoice_id}

  @belongsTo 'user', model: 'User'

  @computed 'formattedPrice', ->
    return 'Free' unless @amount_due
    formatMoney (@amount_due/100.0), symbol: currencies[@currency].replace @currency, ''

  @computed 'transitions', ->
    ret = {}

    if state_actions?[@state]?
      for tr in state_actions[@state] or []
        ret[tr] = transition_name_overrides?[tr] or humanize tr
    ret



export states = 'uncollected awaiting_payment open paid cancelled'.split ' '

export state_options = value: state, label: humanize state for state in states

export base_currency_options = value: cur, label: cur for cur in base_currencies

$w = (str)-> (t for t in str.split(' ') when t isnt '')

export state_actions = obj_with_default [],
  awaiting_payment:     $w 'pay void admin_pay'
  uncollected:          $w 'pay void'

export transition_name_overrides =
  void: "Cancel"
  admin_pay: "Externally paid outside of Stripe"
