import Model, { obj_with_default } from '/lib/model'
import currencies       from '/lib/currencies'
import { formatMoney }  from 'accounting'
import humanize         from 'underscore.string/humanize'
import { stripZone }    from '/lib/datetime'

export default \
class ThirdPartyTicket extends Model
  @className: 'ThirdPartyTicket'
  constructor: ->
    super arguments...
    @id               = null # "5751b0abc6d1c694faed4b40"
    @state            = null # "paid"
    @created_at       = null # "2016-06-03T16:30:36.045Z"
    @updated_at       = null # "2016-06-03T16:30:37.204Z"
    @host_id          = null # "50bc4ca12078f91587017abd"
    @first_name       = null
    @last_name        = null
    @email            = null


    # @listing_id       = null # "5751b063c6d1c694faed4b15"
    # @event_id         = null # "5751b063c6d1c694faed4b19"
    # @user_id          = null # "5751b0aac6d1c694faed4b35"

  @belongsTo 'listing', model: 'Listing'
  @belongsTo 'event',   model: 'Event'
  @belongsTo 'host',    model: 'User'

export states = [
  'unapproved'
  'checkout'
  'unpaid'
  'pending'
  'paid'
  'failed'
  'declined'
  'cancelled'
  'ended'
  'closed'
  'expired'
  'error'
  'partially_paid'
]

export state_options = value: state, label: humanize state for state in states

$w = (str)-> (t for t in str.split(' ') when t isnt '')
export state_actions = obj_with_default [],
  unpaid:         $w 'retry_pay'
  pending:        $w 'retry_pay'
  paid:           $w 'admin_void admin_void_without_refund cancel resend_ticket'
  ended:          $w 'admin_void admin_void_without_refund cancel resend_ticket'
  closed:         $w 'admin_void admin_void_without_refund resend_ticket'
  error:          $w 'admin_void admin_void_without_refund mark_error_as_expired retry_pay resolve_error'
  partially_paid: $w 'admin_void admin_void_without_refund cancel '

export transition_name_overrides =
  retry_pay: "Retry payment"
  resolve_error: 'Resolve error'
  mark_error_as_expired: 'Mark expired'
  admin_void: 'Void - Refund buyer'
  admin_void_without_refund: 'Void - No buyer refund & Host funds removed'
  cancel: 'Void - Host keeps funds'
