import React from 'react'

# components
import Spinner            from '/components/spinner'
import Price              from '/components/price'
import List               from '/components/list'
import Flag               from '/components/flag'
import Datetime           from '/components/datetime'

import {
  withUserPartnershipPayments
} from './queries'

export default \
withUserPartnershipPayments \
class PartnershipPayments extends React.Component
  render: ->
    <div className="PartnershipPayments">
      {@list()}
    </div>

  list: ->
    return <Spinner /> if @props.partnershipPaymentsLoading
    <List>
      {@header()}
      {for partnership_payment in @props.partnership_payments

        <List.Row key={partnership_payment.id}>
            <List.Cell className="date">
              <Datetime date={partnership_payment.created_at} format='MMM d, yyyy'/>
            </List.Cell>
            <List.Cell className="amount">
              <Price amount={partnership_payment.amount} currency={partnership_payment.currency}/>
            </List.Cell>
            <List.Cell className="description">
              {partnership_payment.description}
            </List.Cell>
        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
        <List.Header className="date"> Date </List.Header>
        <List.Header className="amount"> Amount </List.Header>
        <List.Header className="description"> Description </List.Header>
    </List.HeaderRow>
