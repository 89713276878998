import React from 'react'
import './styles'

import flash from '/lib/flash'

import { Link } from 'react-router-dom'

import Pager          from '/components/pager/history-pager'
import Spinner        from '/components/spinner'
import Datetime       from '/components/datetime'
import StateActions   from '/components/state-label/actions'
import List           from '/components/list'
import Filters        from '/components/filters'
import Price          from '/components/price'
import ContactModal   from '/components/contact-modal'
import UserCard       from '/components/user-card'
import NoteWarning    from '/components/note/warning'
import EventCard      from '/components/event-card'

# for filtering
import { state_options } from '/models/ticket'

import { withTickets, withTicketsRPC, withTicketsResend, withTicketSendMessage } from './queries'

export default \
withTickets \
withTicketsRPC \
withTicketsResend \
withTicketSendMessage \
class Tickets extends React.Component
  constructor: (props)->
    super arguments...
    @state =
      modalTicket: null
      modalType: 'Host'
      modalOpen: false

  toggleModal: (type, ticket)=>

    @setState
      modalOpen: not @state.modalOpen
      modalTicket: ticket
      modalType: type

  rpc: (id, method)->
    return @resend_ticket id if method is 'resend_ticket'
    {ticket, errors} = await @props.tickets_rpc id, method
    if errors?.length
      flash.error errors
    else
      flash.success "Ticket updated successfully"

  resend_ticket: (id)=>
    {ticket, errors} = await @props.tickets_resend id
    if errors?.length
      flash.error errors
    else
      flash.success "Ticket re-sent successfully"

  render: ->
    <div className="Tickets">
      <header>
        <h1>Tickets (Orders)</h1>
      </header>
      <section className="content">
        {@filters()}
        {@list()}

        <ContactModal
          sendMessage={ @props.send_message }
          open={@state.modalOpen}
          ticket={@state.modalTicket}
          closeModal={@toggleModal}
          modalType={@state.modalType}
        />
        {@pager()}
      </section>
    </div>

  filters: ->
    <Filters>
      <Filters.Search/>
      <Filters.State options={state_options}/>
      <span className="pull-right">
        <Pager meta={@props.ticketsPagination} buffer={2}/>
      </span>
    </Filters>

  list: ->
    return <Spinner/> if @props.ticketsLoading
    <List>
      {@header()}
      {for ticket in @props.tickets
        <List.Row key={ticket._key}>

          <List.Cell className="id-button-col">
            <Link className="btn btn-default" to={"/tickets/#{ticket.id}"}>
              More info
            </Link>
            <NoteWarning notes={ticket.related_notes}/>
          </List.Cell>

            <List.Cell className="user">
              <UserCard user={ticket.user}>
                {<span className="label label-info">Guest Checkout</span> if ticket.guest_checkout}
              </UserCard>
            </List.Cell>

          <List.Cell className="listing">
            <EventCard event={ticket.event} />
          </List.Cell>

          <List.Cell className="amount">
            <Price amount={ticket.price} currency={ticket.base_currency}/>
          </List.Cell>

          <List.Cell className="num_cost_items">
            {ticket.num_cost_items}
          </List.Cell>

          <List.Cell className="created_at">
            <Datetime date={ticket.created_at}/>
          </List.Cell>

          <List.Cell className="actions">
            <StateActions
              model={ticket}
              onClick={@rpc.bind @, ticket.id}
            />
          </List.Cell>

          <List.Cell className="messages">
            <div className= "message-container">
              <button className="btn btn-default btn-xs contact-top" onClick={@toggleModal.bind @, 'Host', ticket}>Message Host</button>
              <button className="btn btn-default btn-xs" onClick={@toggleModal.bind @, 'Buyer',  ticket}>Message Buyer</button>
            </div>
          </List.Cell>

        </List.Row>
      }
    </List>

  header: ->
    <List.HeaderRow>
      <List.Header className="id"></List.Header>
      <List.Header className="user">User</List.Header>
      <List.Header className="listing">Listing</List.Header>
      <List.Header className="amount">Amount</List.Header>
      <List.Header className="num_cost_items">Total</List.Header>
      <List.Header className="created_at">Created</List.Header>
      <List.Header className="actions">Actions</List.Header>
      <List.Header className="messages">Message</List.Header>
    </List.HeaderRow>

  pager:->
    <div className="text-center">
      <Pager meta={@props.ticketsPagination} buffer={4}/>
    </div>
