import React from 'react'
import PropTypes from 'prop-types'
import './styles'

import humanize from 'underscore.string/humanize'

import ChangeModel from '/models/change'

import { Link } from 'react-router-dom'

import Spinner      from '/components/spinner'

safelyPrint = (val)->
  return val unless val?
  return val if typeof val in ['string', 'number']
  JSON.stringify val

dateFromStamp = (val)->
  return new Date 1000 * val if /^\d{10}$/.test "#{val}"
  null

export default \
class Change extends React.Component
  @propTypes:
    change:   PropTypes.instanceOf(ChangeModel).isRequired
    context:  PropTypes.string
    expanded: PropTypes.bool

  @defaultProps:
    context:  'object'
    expanded: false

  constructor: (props)->
    super arguments...
    @state =
      expanded: props.expanded

  render: ->
    @change     = @props.change
    @changeset  = @change.changeset
    @user       = @changeset?.user

    <div className="Change">
      <div>
        <span className="label label-xs label-target-type #{@change.target_type}" title={@change?.target_id}>{humanize @change.target_type}</span>
        {' '}
        <span>{@change.summary}</span>
        {' '}
        <button className="btn btn-default btn-xs" onClick={=>@setState expanded: not @state.expanded}>{if @state.expanded then 'Hide' else 'Show'} changes</button>

        {if @state.expanded
          <table className="table table-striped">
            <thead>
                <tr key={key}>
                  <th>name</th>
                  <th>before</th>
                  <th>after</th>
                </tr>
            </thead>
            <tbody>
              {for key, vals of @change.diff
                [before, after] = vals

                <tr key={key}>
                  <td className="key">{key}:</td>
                  <td className="before" title={dateFromStamp before}>{safelyPrint before}</td>
                  <td className="after" title={dateFromStamp after}>{safelyPrint after}</td>
                </tr>
              }
            </tbody>
          </table>
        }
      </div>
      {if @props.context is 'object'
        <div>
          <br/>
          {if @user?
            <div>
              <small>
                Changed by
                {' '}
                <Link to={"/users/#{@user.id}"}>
                  {@user.name}
                </Link>
              </small>
            </div>
          else
            <div>
              <small>
                Changed via
                {' '}
                {@changeset?.ctx}
              </small>
            </div>
          }
          <small>
            Changeset:
            <Link to={"/changesets/#{@changeset?.id}"}>
              <span className='id'>{@changeset?.id}</span>
            </Link>
          </small>
          {unless window.location.href.includes @change.target_id
            <small>
              All changes for {@change?.target_type}
              <Link to={"/changes/for/#{@change?.target_type}/#{@change?.target_id}"}>
                <span className='id'>{@change?.target_id}</span>
              </Link>
            </small>
          }
        </div>
      }
    </div>
