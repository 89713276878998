import Model, { obj_with_default } from '/lib/model'
import currencies from '/lib/currencies'
import { formatMoney } from 'accounting'
import humanize from 'underscore.string/humanize'

export default \
class CostItem extends Model
  @className: 'CostItem'
  constructor: ->
    super arguments...
    # @applicable_pass_id: null
    # @base_currency: "CAD"
    # @base_price: 65
    # @buyer_reference_id: "7N10899B"
    # @check_in_state: "checked_out"
    # @checked_in_at: null
    # @created_at: "2016-07-04T17:38:08.045Z"
    # @guest_first_name: "Kieran"
    # @guest_last_name: "Huggins"
    # @guest_name: "Kieran Huggins"
    # @host_fields: [,…]
    # @id: "577a9effc6d1c673fddbf73b"
    # @name: "ticket"
    # @pass: null
    # @prev_check_in_state: null
    # @prev_checked_in_at: null
    # @qr_code: "CVMHL6KWY"
    # @release_state: "released"
    # @src_currency: "CAD"
    # @src_price: 65
    # @state: "paid"
    # @ticket_state: "paid"
    # @transfer: null
    # @updated_at: "2016-07-04T17:38:09.792Z"

    # @buyer: {id: "577a97fcc6d1c673fddbf6f6", slug: "kieran-huggins-05YRNW", first_name: "Kieran",…}
    # @rate: {id: "51789a5fc9d00f062f000fcb", price: 65, src_price: 65, qty: 1, capacity: 6, name: "ticket",…}
    # @user: {id: "577a97fcc6d1c673fddbf6f6", slug: "kieran-huggins-05YRNW", first_name: "Kieran",…}




  # @computed 'name', ->
  #   [ @first_name, @last_name ].join ' '

  @belongsTo 'listing', model: 'Listing'
  @belongsTo 'ticket',  model: 'Ticket'
  @belongsTo 'event',   model: 'Event'
  @belongsTo 'user',    model: 'User'
  @belongsTo 'buyer',   model: 'User'
  @belongsTo 'rate',    model: 'Rate'

  # @hasMany 'host_fields',    model: 'HostField'

  @computed 'formattedPrice', ->
    return 'Free' unless @price
    formatMoney @price, symbol: currencies[@base_currency].replace @base_currency, ''

  @computed 'url', ->
    "#{process.env.MULTIVERSE}/tickets/#{@id}"

  @computed 'isBuyer', ->
    @buyer?.id is @user?.id

  @computed 'transitions', ->
    ret = {}
    for tr in state_actions[@state] or []
      ret[tr] = transition_name_overrides?[tr] or humanize tr
    ret

export states = [
  'unapproved'
  'checkout'
  'unpaid'
  'pending'
  'paid'
  'failed'
  'declined'
  'cancelled'
  'ended'
  'closed'
  'expired'
  'error'
  'transferred'
  'needs_pre_auth'
]

export state_options = value: state, label: humanize state for state in states

$w = (str)-> (t for t in str.split(' ') when t isnt '')
export state_actions = obj_with_default [],
  paid:           $w 'handle_void'
  ended:          $w 'handle_void'
  closed:         $w 'handle_void'

export transition_name_overrides =
  handle_void: "Cancel (partial refund)"
