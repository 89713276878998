import Model, { obj_with_default } from '/lib/model'
import currencies, { base_currencies } from '/lib/currencies'
import { formatMoney } from 'accounting'
import humanize from 'underscore.string/humanize'

$w = (str)-> (t for t in str.split(' ') when t isnt '')

export default \
class Chargeback extends Model
  @className: 'Chargeback'

  @belongsTo 'ticket',  model: 'Ticket'
  @belongsTo 'user',    model: 'User'

  @computed 'transitions', ->
    ret = {}
    for tr in state_actions[@state] or []
      ret[tr] = transition_name_overrides?[tr] or humanize tr
    ret

export states = $w 'won lost open'

export state_options = value: state, label: humanize state for state in states

export base_currency_options = value: cur, label: cur for cur in base_currencies

export state_actions = obj_with_default [],
  open:       $w '' # 'win lose' - NOW AUTOMATED!
