import React from 'react'

import flash from '/lib/flash'

# components

import Spinner    from '/components/spinner'
import Empty      from '/components/empty'
import HeroStats  from '/components/hero-stats'
import Note       from '/components/note'

import format     from 'date-fns/format'
import { Link }   from 'react-router-dom'
import ReactMarkdown  from 'react-markdown'

import {
  ResponsiveContainer
  ComposedChart
  Bar
  XAxis
  YAxis
  Tooltip
} from 'recharts'

import find from 'underscore-es/find'
import FaCloudDownload  from 'react-icons/lib/fa/cloud-download'

import {
  withEvent
  withReport
  withRateCheckInsReset
  withActiveNotes
} from './queries'

export default \
withEvent \
withReport \
withRateCheckInsReset \
withActiveNotes \
class Summary extends React.Component
  constructor: ->
    super arguments...
    @state =
      rate_id: null

  render: ->
    return <Spinner/> if @props.eventLoading
    return <Empty name="Event" /> unless @props.event?.id
    <div className="Summary">
      {@notes()}
      {@reports()}
      {@virtualInfo()}
      {@eventInfo()}
      {@reportExports()}
      {@resetCheckins @props.event}
    </div>

  notes: ->
    return null unless @props.notes?.length
    <div className="notes">
      {<Note key={note._key} note={note}/> for note in @props.notes}
    </div>

  reports:->
    return <Spinner/> if @props.reportLoading
    <div className="reports">
      {@salesOverview @props.report}
      {@salesByDateChart @props.report.sales_by_date}
    </div>

  salesOverview: (report)->
    return <Spinner/> unless report
    <div className="sales-overview">
      <h2>Sales Overview</h2>
      <HeroStats
        data={[
          name: 'Orders'
          value: report.tickets_sold
        ,
          name: 'Guests'
          value: report.ticket_items_sold
        ,
          name: 'Total Sales'
          value: report.amountGross
        ,
          name: 'Net Sales'
          value: report.fulfillmentAmount
        ]}
      />
    </div>

  salesByDateChart: (data)->
    return null unless data and find data, (d)->d.total_amount_net
    <ResponsiveContainer height={250} width={'100%'}>
      <ComposedChart data={data} margin={top: 20, right: 0, left: 0, bottom: 5}>
        <XAxis dataKey="date" hide={true}/>
        <Tooltip/>
        <Bar type="monotone" dataKey="total_amount_net" fill="#28AFFA"/>
      </ComposedChart>
    </ResponsiveContainer>

  reportExports: ->
    reports_by_type =
      csv:
        guests: 'Guestlist'
        attendees: 'Attendees'
        orders: 'Orders'
        cost_items: 'Tickets'
        add_ons: 'Add-Ons'
        cancellations: 'Cancellations and Refunds'
        approvals: 'Requires Approval'
        check_ins: 'Check-Ins'
        chargebacks: 'Chargebacks'
        rebates: 'Rebates'
      pdf:
        service_invoice: 'Service Invoice'
        rebate_invoice: 'Rebate Invoice'
        audit: 'Audit'

    <div className="report-exports">
      <h2>Report Exports</h2>

      <div className="alert alert-warning" role="alert">
        You must be logged into <a href="universe.com">universe.com</a> as someone with permission to view these reports.
      </div>

      <ul>
        {for ext, reports of reports_by_type
          for key, name of reports
            <li key={"#{key}-report-#{ext}"}>
              <a className="btn btn-default" href={"#{process.env.WEB}/event_reports/#{@props.event.id}/#{key}.#{ext}"}>
                <FaCloudDownload/>
                {name} Report
              </a>
            </li>
        }
        <li key={"audit-extended-report-pdf"}>
          <a className="btn btn-default" href={"#{process.env.WEB}/event_reports/#{@props.event.id}/audit.pdf?extended=true"}>
            <FaCloudDownload/>
            {"Extended Audit Report"}
          </a>
        </li>
      </ul>
    </div>

  virtualInfo: ->
    return null unless info = @props.event.listing.virtual_info
    <div className="virtual_info">
      <h2>Virtual event info</h2>
      <blockquote>
        <ReactMarkdown
          className="text"
          source={info}
        />
      </blockquote>
    </div>

  eventInfo: ->
    data =
      "Host Contact Details": @props.event.listing.contact_details,
      "Ticketmaster Discovery ID": @props.event.ticketmaster_id

    return null unless Object.values(data).some (value)-> !!value

    <form className="attributes">
      <h2>Event Information</h2>
      {for k, v of data when v
        <div className="form-group">
          <label className="control-label">{k}</label>
          <input value={v} type='text' className="form-control" disabled="disabled"/>
        </div>
      }
    </form>

  rateCheckInsReset: (id, rate_id) ->
    return unless confirm "Are you sure you want to reset all the check ins for all #{@state.rate.name} tickets at #{format @props.event.startTime, 'MMM d, yyyy h:mm a'}? This cannot be undone."
    {errors} = await @props.rateCheckInsReset id, rate_id
    if errors?.length
      flash.error errors
    else
      flash.success "Rate reset successfully"

  resetCheckins: (event)->
    handleCheckinRateChange = (key, evt)=>
      value = evt.target.value if evt?.target?.value?
      rate = (event?.listing?.rates?.filter (rate) -> rate.id == value)[0]
      @setState "#{key}": rate

    <div className="reset-checkins">
      <h2>Reset Checkins</h2>
      <div>
        <p>Reset the Check In data for all Cost Items for the Selected Event on the selected Rate. After clearing device data in Ticketmanager, all matching Tickets will scan in as if it was their first time.</p>
        <p>Use this feature for multi-day passes to allow for green scan-ins each day.</p>
        <p>Download relevant check-in reports before doing this if you need the previous days checkin data</p>
      </div>
      <div>
        <select className="form-control" onChange={handleCheckinRateChange.bind @, 'rate'}>
          <option value={null}>Select a Rate</option>
          {for key, rate of event.listing.rates
            <option key={"#{rate.id}-report"} value={rate.id}>{rate.name}</option>
          }
        </select>
      </div>

      <div>
        {
          if @state?.rate?.name
            <button className="btn btn-md btn-warning" onClick={@rateCheckInsReset.bind @, event.id, @state.rate.id}>
              Reset Checkins for {@state?.rate?.name}
            </button>
          else
            <button className="btn btn-md btn-warning" disabled>
              Reset Checkins
            </button>
        }
      </div>
    </div>
